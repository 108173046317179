<template>
	<S2SForm title="Transactions">
		<div slot="toolbar-content">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on" @click="fetchFailedTransaction()"><v-icon>refresh</v-icon></v-btn>
				</template>
				<span>Refresh Transactions</span>
			</v-tooltip>
		</div>

		<v-card class="pa-5">
			<v-data-table :headers="transactionHeaders" :items="failedTransactionItems" class="elevation-1" :options.sync="pagination">
				<template v-slot:item.CreateDate="{ item }">
					{{ formatDate(item.CreateDate) }}
				</template>
				<template v-slot:item.Amount="{ item }">
					{{ formatCurrency(item.Amount) }}
				</template>
				<template v-slot:item.retry="{ item }">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="retryTransaction(item)"><v-icon>refresh</v-icon></v-btn>
						</template>
						<span>Retry Transaction</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "DeviceTransactions",

	computed: {
		failedTransactions: function() {
			return this.$store.state.devices.failedTransactions;
		}
	},

	watch: {
		failedTransactions(items) {
			this.failedTransactionItems = items;
		}
	},

	data: function() {
		return {
			transactionHeaders: [
				{ text: "Created Date", value: "CreateDate" },
				{ text: "Amount", value: "Amount" },
				{ text: "Attempts", value: "Attempts" },
				{ text: "Terminal ID", value: "Terminal.TerminalId" },
				{ text: "Shop Code", value: "Terminal.ShopCode" },
				{ text: "Description", value: "Terminal.Description" },
				{ text: "Http Code", value: "HttpCode" },
				{ text: "Response", value: "Response" },
				{ text: "Action", value: "retry" }
			],

			pagination: {
				descending: true,
				itemsPerPage: 10
			},

			failedTransactionItems: []
		};
	},

	mounted() {
		this.fetchFailedTransaction();
	},

	methods: {
		formatCurrency(value) {
			return Utils.formatText(value, Utils.TextType.CURRENCY);
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		retryTransaction(item) {
			// recharge id is item.Id
			this.$store.dispatch("devices/retryFailedTransaction", item.Id);

			this.failedTransactionItems = this.failedTransactionItems.filter(e => e.Id !== item.Id);
		},
		fetchFailedTransaction() {
			this.$store.dispatch("devices/fetchFailedTransactions");
		}
	}
});
</script>
