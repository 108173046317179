var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"Transactions"}},[_c('div',{attrs:{"slot":"toolbar-content"},slot:"toolbar-content"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.fetchFailedTransaction()}}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Transactions")])])],1),_c('v-card',{staticClass:"pa-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.transactionHeaders,"items":_vm.failedTransactionItems,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.CreateDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.CreateDate))+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.Amount))+" ")]}},{key:"item.retry",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.retryTransaction(item)}}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}],null,true)},[_c('span',[_vm._v("Retry Transaction")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }